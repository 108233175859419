<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Whitening']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Whitening" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
      <div v-for="section in sections" :key="section.title">
        <p>{{ section }}</p>
      </div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"We are able (in most situations) to help improve and give you a brighter smile. If you are also considering other restorative work, we may recommend that you complete tooth whitening treatment first, in order to match your brighter smile. We use reputable whitening systems, giving you either as a take home option, or in-chair treatment."
			],
			cards:[
			],
      sections:[
        "Our general advice will include excluding specific foods and drinks would normally encourage stains to collect on tooth surfaces. Additionally, we advise that you wear your whitening trays for at least 2 to 3 hours, depending on the system, for initially around 2 weeks. This may be subject to change depending on the individual. Teeth whitening can only legally be carried out by a GDC-registered dental professional, and is the safest, most effective way to create a brighter smile. Ask your dentist for more information."
      ]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>